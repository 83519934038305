const LayoutDefault = () =>
  import(/* webpackChunkName: "admin" */ '@/layouts/LayoutDefault.vue');
const LayoutData = () =>
  import(/* webpackChunkName: "admin" */ '@/layouts/LayoutData.vue');
const Dashboard = () =>
  import(
    /* webpackChunkName: "admin" */ '@/views/pusat/admin/dashboard/Index.vue'
  );

const Barang = () =>
  import(
    /* webpackChunkName: "admin" */ '@/views/pusat/admin/master/barang/Index.vue'
  );
const TambahBarang = () =>
  import(
    /* webpackChunkName: "admin" */ '@/views/pusat/admin/master/barang/TambahBarang.vue'
  );
const KelompokBarang = () =>
  import(
    /* webpackChunkName: "admin" */ '@/views/pusat/admin/master/kelompok-barang/Index.vue'
  );

const Stockist = () =>
  import(
    /* webpackChunkName: "admin" */ '@/views/pusat/admin/master/stockist/Index.vue'
  );
const StockistCenter = () =>
  import(
    /* webpackChunkName: "admin" */ '@/views/pusat/admin/master/stockist-center/Index.vue'
  );
const KantorPelayanan = () =>
  import(
    /* webpackChunkName: "admin" */ '@/views/pusat/admin/master/kantor-pelayanan/Index.vue'
  );
const Member = () =>
  import(
    /* webpackChunkName: "admin" */ '@/views/pusat/admin/master/member/Index.vue'
  );
const TambahMember = () =>
  import(
    /* webpackChunkName: "admin" */ '@/views/pusat/admin/master/member/Tambah.vue'
  );
const Supplier = () =>
  import(
    /* webpackChunkName: "admin" */ '@/views/pusat/admin/master/supplier/Index.vue'
  );
const Ekspedisi = () =>
  import(
    /* webpackChunkName: "admin" */ '@/views/pusat/admin/master/ekspedisi/Index.vue'
  );
const Bank = () =>
  import(
    /* webpackChunkName: "admin" */ '@/views/pusat/admin/master/bank/Index.vue'
  );
const WilayahKabupaten = () =>
  import(
    /* webpackChunkName: "admin" */ '@/views/pusat/admin/master/wilayah/Index.vue'
  );
const SetupPenjualan = () =>
  import(
    /* webpackChunkName: "admin" */ '@/views/pusat/admin/setup/Penjualan.vue'
  );
const SetupKasir = () =>
  import(/* webpackChunkName: "admin" */ '@/views/pusat/admin/setup/Kasir.vue');
const SetupStockist = () =>
  import(
    /* webpackChunkName: "admin" */ '@/views/pusat/admin/setup/Stockist.vue'
  );
const SetupGudang = () =>
  import(
    /* webpackChunkName: "admin" */ '@/views/pusat/admin/setup/Gudang.vue'
  );
const SetupMitra = () =>
  import(/* webpackChunkName: "admin" */ '@/views/pusat/admin/setup/Mitra.vue');
const User = () =>
  import(
    /* webpackChunkName: "admin" */ '@/views/pusat/admin/master/user/Index.vue'
  );
const TambahUser = () =>
  import(
    /* webpackChunkName: "admin" */ '@/views/pusat/admin/master/user/TambahUser.vue'
  );

const Pendidikan = () =>
  import(
    /* webpackChunkName: "admin" */ '@/views/pusat/admin/master/educations/Index.vue'
  );

const Pekerjaan = () =>
  import(
    /* webpackChunkName: "admin" */ '@/views/pusat/admin/master/professions/Index.vue'
  );
const HakAkses = () =>
  import(
    /* webpackChunkName: "admin" */ '@/views/pusat/admin/master/hak-akses/Index.vue'
  );
const PeriodeTutupBuku = () =>
  import(
    /* webpackChunkName: "admin" */ '@/views/pusat/admin/setup/periode-tutup-buku/index.vue'
  );

const PeriodeTutupBukuBonus = () =>
  import(
    /* webpackChunkName: "admin" */ '@/views/pusat/admin/setup/periode-tutup-buku/bonus.vue'
  );
const PeriodeTutupBukuPenjualan = () =>
  import(
    /* webpackChunkName: "admin" */ '@/views/pusat/admin/setup/periode-tutup-buku/penjualan.vue'
  );
const Promo = () =>
  import(
    /* webpackChunkName: "admin" */ '@/views/pusat/admin/setup/promo/index.vue'
  );
const TambahPromo = () =>
  import(
    /* webpackChunkName: "admin" */ '@/views/pusat/admin/setup/promo/create.vue'
  );

const MemberWhatsapp = () =>
  import(
    /* webpackChunkName: "admin" */ '@/views/pusat/admin/log/MemberWhatsapp.vue'
  );
const MailLog = () =>
  import(
    /* webpackChunkName: "admin" */ '@/views/pusat/admin/log/MailLog.vue'
  );
const WhatsappLog = () =>
  import(
    /* webpackChunkName: "admin" */ '@/views/pusat/admin/log/WhatsappLog.vue'
  );

const Bonus = () =>
  import(
    /* webpackChunkName: "admin" */ '@/views/pusat/admin/Bonus.vue'
  );
const WaPinMemberN = () =>
  import(
    /* webpackChunkName: "admin" */ '@/views/pusat/admin/wa-pin-member-n/index.vue'
  );
const GenerateQrCode = () =>
  import(
    /* webpackChunkName: "admin" */ '@/views/pusat/admin/qr-code/generate-qr-code/Index.vue'
  );
const DetailGenerateQrCode = () =>
  import(
    /* webpackChunkName: "admin" */ '@/views/pusat/admin/qr-code/generate-qr-code/Detail.vue'
  );
const LaporanQrCode = () =>
  import(
    /* webpackChunkName: "admin" */ '@/views/pusat/admin/qr-code/laporan/Index.vue'
  );

let root = '/admin';

export default [
  {
    path: root + '/',
    component: Dashboard,
    meta: {
      auth: true,
      title: 'Dashboard',
      layout: LayoutDefault,
    },
  },
  {
    path: root + '/master/',
    component: LayoutData,
    children: [
      // Route Master Barang
      {
        path: 'barang/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Barang',
        },
        children: [
          {
            path: '',
            component: Barang,
            name: 'master.barang',
            meta: {
              auth: true,
              title: 'Master Barang',
              layout: LayoutDefault,
            },
          },
          {
            path: 'tambah-barang',
            component: TambahBarang,
            name: 'master.barang.tambah',
            meta: {
              auth: true,
              title: 'Tambah Master Barang',
              layout: LayoutDefault,
              breadcrumb: 'Tambah Barang',
            },
          },
          {
            path: ':id',
            component: TambahBarang,
            props: true,
            meta: {
              auth: true,
              title: 'Kelola Master Barang',
              layout: LayoutDefault,
              breadcrumb: 'Kelola Barang',
            },
          },
        ],
      },
      {
        path: 'kelompok-barang/',
        component: KelompokBarang,
        meta: {
          title: 'Kelompok Barang',
          layout: LayoutDefault,
          auth: true
        },
      },
      // Route Master Stockist
      {
        path: 'stockist/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Stockist',
        },
        children: [
          {
            path: '',
            component: Stockist,
            name: 'master.stockist',
            meta: {
              auth: true,
              title: 'Stockist',
              layout: LayoutDefault,
            },
          },
          {
            path: ':id',
            name: 'master.stockist.edit',
            component: () =>
              import('@/views/pusat/admin/master/stockist/Edit.vue'),
            meta: {
              auth: true,
              title: 'Stockist',
              layout: LayoutDefault,
            },
          },
        ],
      },
      // Route Master Stockist Center
      {
        path: 'stockist-center/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Stockist Center',
        },
        children: [
          {
            path: '',
            component: StockistCenter,
            meta: {
              auth: true,
              title: 'Stockist Center',
              layout: LayoutDefault,
            },
          },
          {
            path: ':id',
            component: () =>
              import('@/views/pusat/admin/master/stockist-center/Edit.vue'),
            meta: {
              auth: true,
              title: 'Stockist Center',
              layout: LayoutDefault,
            },
          },
        ],
      },
      {
        path: 'kantor-pelayanan/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Kantor Pelayanan',
        },
        children: [
          {
            path: '',
            component: KantorPelayanan,
            meta: {
              auth: true,
              title: 'Kantor Pelayanan',
              layout: LayoutDefault,
            },
          },
          {
            path: ':id',
            component: () =>
              import('@/views/pusat/admin/master/kantor-pelayanan/Edit.vue'),
            meta: {
              auth: true,
              title: 'Kantor Pelayanan',
              layout: LayoutDefault,
            },
          },
        ],
      },
      // Route Master Member
      {
        path: 'member/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Mitra Usaha',
        },
        children: [
          {
            path: '',
            component: Member,
            meta: {
              auth: true,
              title: 'Mitra Usaha',
              layout: LayoutDefault,
            },
          },
          {
            path: 'tambah-member',
            component: TambahMember,
            meta: {
              auth: true,
              title: 'Tambah Mitra Usaha',
              layout: LayoutDefault,
            },
          },
          {
            path: ':id',
            component: () =>
              import('@/views/pusat/admin/master/member/Edit.vue'),
            meta: {
              auth: true,
              title: 'Mitra Usaha',
              layout: LayoutDefault,
            },
          },
        ],
      },
      // Route Master Supplier
      {
        path: 'supplier/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Supplier',
        },
        children: [
          {
            path: '',
            component: Supplier,
            meta: {
              auth: true,
              title: 'Master Supplier',
              layout: LayoutDefault,
            },
          },
        ],
      },
      // Route Master USer
      {
        path: 'users/',
        component: LayoutData,
        meta: {
          breadcrumb: 'User',
        },
        children: [
          {
            path: '',
            name: 'master.user',
            component: User,
            meta: {
              auth: true,
              title: 'User',
              layout: LayoutDefault,
            },
          },
          {
            path: 'tambah-user',
            name: 'master.user.tambah',
            component: TambahUser,
            meta: {
              auth: true,
              title: 'Tambah User',
              layout: LayoutDefault,
              breadcrumb: 'Tambah User',
            },
          },
          {
            path: 'tambah-user/:id',
            name: 'master.user.edit',
            component: TambahUser,
            meta: {
              auth: true,
              title: 'Edit User',
              layout: LayoutDefault,
              breadcrumb: 'Edit User',
            },
          },
        ],
      },
      // Route Master Ekspedisi
      {
        path: 'ekspedisi/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Ekspedisi',
        },
        children: [
          {
            path: '',
            name: 'expedition.index',
            component: Ekspedisi,
            meta: {
              auth: true,
              title: 'Ekspedisi',
              layout: LayoutDefault,
            },
          },
        ],
      },
      // Route Master Bank
      {
        path: 'bank/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Bank',
        },
        children: [
          {
            path: '',
            component: Bank,
            meta: {
              auth: true,
              title: 'Bank',
              layout: LayoutDefault,
            },
          },
        ],
      },
      {
        path: 'wilayah-kabupaten',
        component: LayoutData,
        meta: {
          breadcrumb: 'Wilayah',
        },
        children: [
          {
            path: '',
            component: WilayahKabupaten,
            meta: {
              auth: true,
              title: 'Wilayah Kabupaten',
              layout: LayoutDefault,
            },
          },
        ],
      },
      {
        path: 'pendidikan',
        component: LayoutData,
        meta: {
          breadcrumb: 'Pendidikan',
        },
        children: [
          {
            path: '',
            component: Pendidikan,
            meta: {
              title: 'Pendidikan',
              auth: true,
              layout: LayoutDefault,
            },
          },
        ],
      },
      {
        path: 'pekerjaan',
        component: LayoutData,
        meta: {
          breadcrumb: 'Pekerjaan',
        },
        children: [
          {
            path: '',
            component: Pekerjaan,
            meta: {
              auth: true,
              title: 'Pekerjaan',
              layout: LayoutDefault,
            },
          },
        ],
      },
      {
        path: 'hak-akses',
        component: LayoutData,
        meta: {
          breadcrumb: 'Hak Akses',
        },
        children: [
          {
            path: '',
            component: HakAkses,
            meta: {
              auth: true,
              title: 'Hak Akses',
              layout: LayoutDefault,
            },
          },
        ],
      },
    ],
  },
  // Menu Setup
  {
    path: root + '/setup/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Setup',
    },
    children: [
      {
        path: 'penjualan',
        component: SetupPenjualan,
        meta: {
          auth: true,
          title: 'Setup Penjualan',
          layout: LayoutDefault,
        },
      },
      {
        path: 'kasir',
        component: SetupKasir,
        meta: {
          auth: true,
          title: 'Setup Kasir',
          layout: LayoutDefault,
        },
      },
      {
        path: 'Stockist',
        component: SetupStockist,
        meta: {
          auth: true,
          title: 'Setup Stockist',
          layout: LayoutDefault,
        },
      },
      {
        path: 'Gudang',
        component: SetupGudang,
        meta: {
          auth: true,
          title: 'Setup Gudang',
          layout: LayoutDefault,
        },
      },
      {
        path: 'Mitra',
        component: SetupMitra,
        meta: {
          auth: true,
          title: 'Setup Mitra',
          layout: LayoutDefault,
        },
      },
      {
        path: 'promo/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Promo',
        },
        children: [
          {
            path: '',
            component: Promo,
            meta: {
              auth: true,
              title: 'Promo',
              layout: LayoutDefault,
            },
          },
          {
            path: 'tambah-promo',
            name: 'admin.setup.promo.tambah',
            component: TambahPromo,
            meta: {
              auth: true,
              title: 'Tambah Promo',
              layout: LayoutDefault,
            },
          },
          {
            path: ':id',
            component: TambahPromo,
            meta: {
              auth: true,
              title: 'Tambah Promo',
              layout: LayoutDefault,
            },
          },
        ],
      },
      {
        path: 'periode-tutup-buku/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Periode Tutup Buku',
        },
        children: [
          {
            path: '',
            component: PeriodeTutupBuku,
            meta: {
              auth: true,
              title: 'Periode Tutup Buku',
              layout: LayoutDefault,
            },
          },
        ],
      },
      {
        path: 'periode-tutup-buku/bonus',
        component: LayoutData,
        meta: {
          breadcrumb: 'Periode Tutup Buku Bonus',
        },
        children: [
          {
            path: '',
            component: PeriodeTutupBukuBonus,
            meta: {
              auth: true,
              title: 'Periode Tutup Buku Bonus',
              layout: LayoutDefault,
            },
          },
        ],
      },
      {
        path: 'periode-tutup-buku/penjualan',
        component: LayoutData,
        meta: {
          breadcrumb: 'Periode Tutup Buku Penjualan',
        },
        children: [
          {
            path: '',
            component: PeriodeTutupBukuPenjualan,
            meta: {
              auth: true,
              title: 'Periode Tutup Buku Penjualan',
              layout: LayoutDefault,
            },
          },
        ],
      },
    ],
  },
  {
    path: root + '/log/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Log',
    },
    children: [
      {
        path: 'member-whatsapp',
        component: MemberWhatsapp,
        meta: {
          auth: true,
          title: 'Member Whatsapp Log',
          layout: LayoutDefault,
        },
      },
      {
        path: 'mail',
        component: MailLog,
        meta: {
          auth: true,
          title: 'Mail Log',
          layout: LayoutDefault,
        },
      },
      {
        path: 'whatsapp',
        component: WhatsappLog,
        meta: {
          auth: true,
          title: 'Whatsapp Log',
          layout: LayoutDefault,
        },
      },
    ]
  },
  {
    path: root + '/bonus',
    component: Bonus,
    meta: {
      auth: true,
      title: 'Ambil Data Bonus',
      layout: LayoutDefault,
    },
  },
  {
    path: root + '/wa-pin-member-n',
    component: WaPinMemberN,
    meta: {
      auth: true,
      title: 'WA PIN Member N',
      layout: LayoutDefault,
    },
  },
  {
    path: root + '/qrcode/',
    component: LayoutData,
    meta: {
      breadcrumb: 'QR Code',
    },
    children: [
      {
        path: 'generate',
        component: LayoutData,
        meta: {
          breadcrumb: 'Generate QR Code'
        },
        children: [
          {
            path: '',
            component: GenerateQrCode,
            meta: {
              auth: true,
              title: 'Generate QR Code',
              layout: LayoutDefault,
            },
          },
          {
            path: ':id',
            component: DetailGenerateQrCode,
            name: 'generate-qr-code.detail',
            meta: {
              auth: true,
              title: 'Detail Generate QR Code',
              layout: LayoutDefault,
            },
          },
        ]
      },
      {
        path: 'laporan',
        component: LaporanQrCode,
        meta: {
          auth: true,
          title: 'Laporan QR Code',
          layout: LayoutDefault,
        },
      },
    ]
  },
];
