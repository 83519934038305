import VueRouter from 'vue-router';
import { StorageService } from '@/services/storage.service';
import { checkRole } from '@/services/utils.service';
import { baseRoutes } from './base';
import Pusat from '@/router/modules/pusat';
import Stockist from '@/router/modules/stockist';
import StockistCenter from '@/router/modules/stockist_center';
import Member from '@/router/modules/member';
import Pelayanan from '@/router/modules/kantor_pelayanan';
import Supplier from './modules/supplier';

export default function setupRoutesByRole(router) {
  let base = baseRoutes;
  // register router by role
  if (StorageService.getUser() !== null && checkRole()) {
    if (checkRole().attributes.name === 'Pusat') {
      base = base.concat(Pusat);
    } else if (checkRole().attributes.name === 'Stockist') {
      base = base.concat(Stockist);
    } else if (checkRole().attributes.name === 'Stockist Center') {
      base = base.concat(StockistCenter);
    } else if (checkRole().attributes.name === 'Member') {
      base = base.concat(Member);
    } else if (checkRole().attributes.name === 'Kantor Pelayanan') {
      base = base.concat(Pelayanan);
    } else if (checkRole().attributes.name === 'Supplier') {
      base = base.concat(Supplier);
    }
  }

  const createRouter = () =>
    new VueRouter({
      mode: 'history',
      routes: base,
    });
  const newRouter = createRouter();

  router.matcher = newRouter.matcher; // the relevant part
}
