<template>
  <div>
    <div
      v-if="showing"
      :id="id"
      class="fixed inset-0 z-10 overflow-y-auto px-4"
      @click.self="showing != showing"
    >
      <transition
        name="customFade"
        enter-active-class="duration-300 ease-out"
        leave-active-class="duration-200 ease-in"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
        appear
      >
        <div
          @click="close"
          class="fixed inset-0 bg-gray-500 bg-opacity-75"
          aria-hidden="true"
        ></div>
      </transition>
      <transition
        @before-leave="backdropLeaving = true"
        @after-leave="backdropLeaving = false"
        name="smoothScale"
        enter-active-class="duration-300 ease-out"
        leave-active-class="duration-200 ease-in"
        enter-class="opacity-0 scale-95"
        enter-to-class="opacity-100 scale-100"
        leave-class="opacity-100 scale-100"
        leave-to-class="opacity-0 scale-95"
        appear
      >
        <div
          class="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center sm:p-0"
        >
          <div
            class="inline-block transform rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full"
            :class="[size, overflowable ? '' : 'overflow-hidden', withPadding ? 'p-8' : '']"
          >
            <button
              v-if="showClose"
              @click.prevent="close"
              class="absolute top-0 right-0 mr-2 mt-2 inline-flex items-center rounded-lg border border-gray-300 p-1 text-gray-500 shadow-sm hover:bg-gray-100"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
            <div class="w-full">
              <slot />
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showing: {
      required: true,
      type: Boolean,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    backgroundClose: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'max-w-3xl',
    },
    overflowable: {
      type: Boolean,
      default: false,
    },
    withPadding: {
      type: Boolean,
      default: true,
    },
    id: String
  },
  data() {
    return {
      backdropLeaving: false,
      cardLeaving: false,
    };
  },
  computed: {
    leaving() {
      return this.backdropLeaving || this.cardLeaving;
    },
  },
  watch: {
    showing(value) {
      if (value) {
        document.querySelector('body').classList.add('overflow-hidden');
        this.$emit('opened')
      } else {
        document.querySelector('body').classList.remove('overflow-hidden');
      }
    }
  },
  methods: {
    close() {
      document.querySelector('body').classList.remove('overflow-hidden');
      this.$emit('close');
    },
    closeIfShown() {
      if (this.showClose && this.backgroundClose) {
        this.close();
      }
    },
    handleResize(e) {
      if (e.key === 'Escape' && this.showing) {
        this.close();
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleResize);
  },
  mounted: function () {
    if (this.showClose) {
      document.addEventListener('keydown', this.handleResize);
    }
  },
};
</script>
