const LayoutDefault = () =>
  import(/* webpackChunkName: "supplier" */ '@/layouts/LayoutDefault.vue');
const LayoutData = () =>
  import(/* webpackChunkName: "supplier" */ '@/layouts/LayoutData.vue');

const Gudang = () =>
  import(
    /* webpackChunkName: "supplier" */ '@/views/supplier/gudang/Index.vue'
  );

const Stock = () =>
  import(
    /* webpackChunkName: "supplier" */ '@/views/supplier/gudang/stock/Index.vue'
  );
const ManajemenBatchStok = () =>
  import(
    /* webpackChunkName: "supplier" */ '@/views/supplier/gudang/manajemen-batch-stok/Index.vue'
  );
const TambahManajemenBatchStok = () =>
  import(
    /* webpackChunkName: "supplier" */ '@/views/supplier/gudang/manajemen-batch-stok/Tambah.vue'
  );
const PersiapanStok = () =>
  import(
    /* webpackChunkName: "supplier" */ '@/views/supplier/gudang/persiapan-stok/Index.vue'
  );
const TambahPersiapanStok = () =>
  import(
    /* webpackChunkName: "supplier" */ '@/views/supplier/gudang/persiapan-stok/Tambah.vue'
  );
const PengeluaranBarangdariSupplier = () =>
  import(
    /* webpackChunkName: "supplier" */ '@/views/supplier/gudang/pengeluaran/barang-dari-supplier/Index.vue'
  );
const TambahPengeluaranBarangdariSupplier = () =>
  import(
    /* webpackChunkName: "supplier" */ '@/views/supplier/gudang/pengeluaran/barang-dari-supplier/Tambah.vue'
  );
const PengemasanPengeluaranBarangdariSupplier = () =>
  import(
    /* webpackChunkName: "supplier" */ '@/views/supplier/gudang/pengeluaran/barang-dari-supplier/Pengemasan.vue'
  );
const PengirimanBarangdariSupplier = () =>
  import(
    /* webpackChunkName: "supplier" */ '@/views/supplier/gudang/pengiriman/barang-dari-supplier/Index.vue'
  );
const TambahPengirimanBarangdariSupplier = () =>
  import(
    /* webpackChunkName: "supplier" */ '@/views/supplier/gudang/pengiriman/barang-dari-supplier/Tambah.vue'
  );
const ProdukQr = () =>
  import(
    /* webpackChunkName: "supplier" */ '@/views/supplier/gudang/produk-qr/Index.vue'
  );
const SusulanBarangDariSupplier = () =>
  import(
    /* webpackChunkName: "supplier" */ '@/views/supplier/gudang/susulan/barang-dari-supplier/Index.vue'
  );
const TambahSusulanBarangDariSupplier = () =>
  import(
    /* webpackChunkName: "supplier" */ '@/views/supplier/gudang/susulan/barang-dari-supplier/Tambah.vue'
  );

export default [
  {
    path: '/gudang',
    component: LayoutData,
    children: [
      {
        path: '',
        component: Gudang,
        meta: {
          auth: true,
          title: 'Gudang',
          layout: LayoutDefault,
        },
      },
      {
        path: 'stock',
        component: Stock,
        meta: {
          auth: true,
          title: 'Stok',
          layout: LayoutDefault,
        },
      },
      {
        path: 'manajemen-batch-stok',
        component: LayoutData,
        children: [
          {
            path: '',
            component: ManajemenBatchStok,
            name: 'manajemen-batch-stok',
            meta: {
              auth: true,
              title: 'Manajemen Batch Stok',
              layout: LayoutDefault,
            },
          },
          {
            path: 'tambah',
            component: TambahManajemenBatchStok,
            name: 'manajemen-batch-stok.tambah',
            meta: {
              auth: true,
              title: 'Tambah Batch Stok',
              layout: LayoutDefault,
            },
          },
        ],
      },
      {
        path: 'persiapan-stok',
        component: LayoutData,
        children: [
          {
            path: '',
            component: PersiapanStok,
            name: 'persiapan-stok',
            meta: {
              auth: true,
              title: 'Persiapan Stok',
              layout: LayoutDefault,
            },
          },
          {
            path: ':id',
            component: TambahPersiapanStok,
            name: 'persiapan-stok.tambah',
            meta: {
              auth: true,
              title: 'Persiapan Stok',
              layout: LayoutDefault,
            },
          },
        ],
      },
      {
        path: 'pengeluaran',
        component: LayoutData,
        children: [
          {
            path: 'barang-dari-supplier',
            component: LayoutData,
            meta: {
              breadcrumb: 'Pengeluaran Barang dari Supplier',
            },
            children: [
              {
                path: '',
                name: 'gudang.pengeluaran.dari-supplier',
                component: PengeluaranBarangdariSupplier,
                meta: {
                  auth: true,
                  title: 'Pengeluaran Barang dari Supplier | Gudang',
                  layout: LayoutDefault,
                },
              },
              {
                path: ':id',
                component: LayoutData,
                meta: {
                  breadcrumb: 'Detail Pengeluaran Barang Dari Supplier',
                },
                children: [
                  {
                    path: '',
                    component: TambahPengeluaranBarangdariSupplier,
                    name: 'gudang.pengeluaran.dari-supplier.tambah',
                    meta: {
                      auth: true,
                      title: 'Detail Pengeluaran Dari Supplier | Gudang',
                      layout: LayoutDefault,
                    }
                  },
                  {
                    path: 'pengemasan',
                    component: PengemasanPengeluaranBarangdariSupplier,
                    meta: {
                      auth: true,
                      title: 'Pengemasan Barang | Gudang',
                      layout: LayoutDefault,
                      breadcrumb: 'Pengemasan Dari Supplier',
                    }
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'pengiriman',
        component: LayoutData,
        children: [
          {
            path: 'barang-dari-supplier/',
            component: LayoutData,
            meta: {
              breadcrumb: 'Pengiriman Barang dari Supplier',
            },
            children: [
              {
                path: '',
                component: PengirimanBarangdariSupplier,
                meta: {
                  auth: true,
                  title: 'Pengiriman Barang dari Supplier | Gudang',
                  layout: LayoutDefault,
                },
              },
              {
                path: ':id',
                component: TambahPengirimanBarangdariSupplier,
                meta: {
                  auth: true,
                  title: 'Tambah Pengiriman Barang dari Supplier | Gudang',
                  layout: LayoutDefault,
                  breadcrumb: 'Tambah Pengiriman Barang dari Supplier',
                },
              },
            ],
          },
        ],
      },
      {
        path: 'produk-qr',
        component: ProdukQr,
        meta: {
          auth: true,
          title: 'Produk QR',
          layout: LayoutDefault,
        },
      },
      {
        path: 'susulan',
        component: LayoutData,
        meta: {
          breadcrumb: 'Susulan',
        },
        children: [
          {
            path: 'barang-dari-supplier/',
            component: LayoutData,
            meta: {
              breadcrumb: 'Pengiriman Susulan dari Supplier',
            },
            children: [
              {
                path: '',
                component: SusulanBarangDariSupplier,
                name: 'gudang.susulan.barang-dari.supplier',
                meta: {
                  auth: true,
                  title: 'Pengiriman Susulan dari Supplier | Gudang',
                  layout: LayoutDefault,
                },
              },
              {
                path: ':id',
                name: 'gudang.susulan.barang-dari.supplier.tambah',
                component: TambahSusulanBarangDariSupplier,
                meta: {
                  auth: true,
                  title: 'Tambah Pengiriman Susulan dari Supplier | Gudang',
                  layout: LayoutDefault,
                  breadcrumb: 'Detail Pengiriman Susulan dari Supplier',
                },
              },
            ],
          },
        ],
      },
    ],
  },
];
