<template>
  <div id="app" class="font-serif">
    <version-alert v-if="!getVersionUpdated" />
    <transition
      enter-active-class="fadeInRight duration-100"
      leave-active-class="fadeOutRight duration-100"
    >
      <template v-if="getAlertData">
        <Alert :data="getAlertData" :status="getStatus" />
      </template>
    </transition>
    <ModalMenu :show-menu="isMenuShown" @onClose="onCloseMenu" />
    <component :is="this.$route.meta.layout || 'div'">
      <router-view :key="$route.fullpath" />
    </component>
    <div
      :class="[
        // define style
        'bg-yellow-400',
        'bg-blue-600',
        'bg-purple-500',
        'bg-green-600',
        'bg-red-600',
        'bg-indigo-400',

        'border-blue-600',
        'border-yellow-800',
        'border-purple-800',
        'border-green-600',
        'border-gray-600',
        'text-blue-600',
      ]"
    ></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Alert from '@/components/flowbite/Alert.vue';
import ModalMenu from '@/components/base/ModalMenu.vue';
import VersionAlert from './components/VersionAlert.vue';
import './index.css';

export default {
  name: 'App',
  components: {
    Alert,
    ModalMenu,
    VersionAlert
  },
  data() {
    const preferences = JSON.parse(localStorage.getItem('preferences'));
    return {
      preferences,
      layout: 'div',
      isMenuShown: false,
    };
  },
  mounted() {
    document.onkeydown = this.KeyPress;

    this.setupBarcodeScanner()
  },
  computed: {
    ...mapGetters({
      getAlertData: 'alert/getData',
      getStatus: 'alert/getStatus',
      getVersionUpdated: 'auth/getVersionUpdated'
    }),
  },
  methods: {
    KeyPress(e) {
      var evtobj = window.event ? event : e;
      if (evtobj.key == 'm' && evtobj.ctrlKey) {
        this.isMenuShown = true;
      }
    },
    onCloseMenu() {
      this.isMenuShown = false;
    },
    setupBarcodeScanner() {
      let timeout
      let lastKeyPressTime = 0
      let text = ''

      document.addEventListener('keydown', (e) => {
        clearTimeout(timeout)

        const now = new Date().getTime()
        const timeDiff = now - lastKeyPressTime
        lastKeyPressTime = now

        if (timeDiff > 30) {
          text = ''
        }

        if (/^[a-zA-Z0-9\-_]$/.test(e.key)) {
          text += e.key
        }

        timeout = setTimeout(() => {
          if (text.length <= 3) {
            return
          }

          document.dispatchEvent(new CustomEvent('barcode-scan', {
            detail: {
              raw: text,
              text: text.replace(/Enter|Shift/g, '')
            }
          }))

          text = ''
        }, 50)
      })
    }
  },
  created() {
    if (this.preferences?.newThemes) {
      document.documentElement.style.fontFamily = 'Inter';
    } else {
      document.documentElement.style.fontFamily = 'Poppins';
    }

    this.$router.afterEach(() => document.querySelector('body').classList.remove('overflow-hidden'))
  },
};
</script>
