<template>
  <div class="flex min-h-screen items-center justify-center bg-gray-50 px-4">
    <div class="w-full max-w-lg">
      <base-card title="Reset Password">
        <form class="space-y-4" @submit.prevent="onSubmit">
          <base-input
            inset
            type="password"
            label="Password"
            placeholder="Password"
            v-model="form.password"
            required
          />
          <base-input
            inset
            type="password"
            label="Konfirmasi Password"
            placeholder="Konfirmasi Password"
            v-model="form.password_confirmation"
            required
          />
          <base-button fullwidth>Reset Password</base-button>
        </form>
      </base-card>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { mapActions } from 'vuex';

export default {
  mixins: [requestMixin],
  data() {
    return {
      loading: false,
      form: {
        email: null,
        password: null,
        password_confirmation: null,
      },
    };
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    async onSubmit() {
      this.loading = true;

      const [, error] = await this.request('/api/v1/password/reset', {
        method: 'post',
        data: {
          ...this.form,
          token: this.$route.params.token,
        },
      });

      if (error) {
        if (error.response.status === 400) {
          this.createAlert({
            status: 'error',
            data: 'Email atau token tidak ditemukan',
          });
        } else {
          this.createAlert({
            status: 'error',
            data: this.getRequestErrorMessage(error),
          });
        }
      } else {
        this.createAlert({
          status: 'success',
          data: 'Password berhasil diperbarui',
        });

        this.$router.push({ name: 'Login' });
      }

      this.loading = false;
    }
  },
  created() {
    this.form.email = atob(this.$route.params.email)
  }
};
</script>
