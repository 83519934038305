<template>
  <div
    v-if="getUser.data?.attributes"
    class="flex flex-col justify-center gap-4 md:flex-row"
  >
    <div class="flex flex-shrink-0 flex-col items-center gap-2">
      <img
        class="inline-block h-20 w-20 rounded-full"
        :src="getProfileUrl"
        id="profile_pic"
        alt="Your current profile image"
      />
      <input
        @change="handleChangeFile"
        type="file"
        id="profile_pic"
        class="hidden"
      />
      <base-button
        @click="handleProfileUpload"
        size="sm"
        color="white"
        :self-end="false"
      >
        Edit
      </base-button>
    </div>

    <div class="space-y-4">
      <base-card title="Ubah Profil">
        <div class="space-y-4">
          <base-input inset disabled label="Kantor" v-model="officeTypeLabel" />
          <base-input
            inset
            disabled
            label="Kode Kantor"
            v-model="getOffice.data.attributes.code"
          />
          <base-input inset disabled label="Hak Akses" v-model="user.role" />
          <template v-if="isMember">
            <base-input
              inset
              disabled
              label="Nama"
              v-model="getOffice.data.attributes.name"
            />
            <base-input
              inset
              disabled
              label="Username"
              v-model="getOffice.data.attributes.email"
            />
            <base-input
              inset
              disabled
              label="No HP"
              v-model="getOffice.data.attributes.phone"
            />
            <base-input
              inset
              disabled
              label="Alamat"
              v-model="getOffice.data.attributes.address"
            />
          </template>
          <template v-else>
            <base-input inset disabled label="Nama" v-model="user.name" />
            <base-input inset disabled label="Email" v-model="user.email" />
            <base-input inset disabled label="No HP" v-model="user.hp" />
            <base-input inset disabled label="Alamat" v-model="user.address" />
          </template>
        </div>
      </base-card>

      <edit-profile-email
        v-if="!user.email_changed"
        :verify-email="user.verifyEmail"
        :user-id="user.id"
        @created="onCreatedUpdateEmail"
        @verified="onEmailVerified"
      />

      <edit-profile-password :user-id="user.id" />

      <base-card title="Riwayat Login">
        <datatable :paginated="false" :footer="false">
          <template v-slot:thead>
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Tanggal
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  IP Address
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Perangkat
                </th>
              </tr>
            </thead>
          </template>
          <template v-slot:tbody>
            <tbody>
              <tr
                class="bg-white"
                v-for="(data, index) in getAll['user-logs'].data"
                :key="index"
              >
                <td
                  class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                >
                  {{ dayjs(data.attributes.createdAt).format('ll LT') }}
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                >
                  {{ data.attributes.ipAddress }}
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                >
                  {{ getUserAgent(data.user_agent) }}
                </td>
              </tr>
            </tbody>
          </template>
        </datatable>
      </base-card>
    </div>

    <transition name="fade">
      <div
        v-if="success"
        class="fixed inset-0 z-10 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <span
            class="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
            >&#8203;</span
          >
          <div
            class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle"
          >
            <div>
              <div
                class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100"
              >
                <svg
                  class="h-6 w-6 text-green-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <slot name="heading"> Data Berhasil diubah </slot>
              </div>
            </div>
            <div class="mt-5 sm:mt-6">
              <div class="flex items-center gap-2">
                <base-button fullwidth @click="closeModal">
                  Selesai
                </base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div
        v-if="failed"
        class="fixed inset-0 z-10 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <span
            class="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
            >&#8203;</span
          >
          <div
            class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle"
          >
            <div>
              <div
                class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 text-red-600"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <slot name="heading">
                  {{ getError }}
                </slot>
              </div>
            </div>
            <div class="mt-5 sm:mt-6">
              <div class="flex items-center gap-2">
                <base-button fullwidth @click="closeModal">
                  Selesai
                </base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <loading v-if="isLoading"></loading>
  </div>
</template>

<script>
import { StorageService } from '@/services/storage.service';
import { mapActions, mapGetters } from 'vuex';
import Datatable from '@/components/base/Datatable';
import dayjs from 'dayjs';
import BaseButton from '@/components/base/BaseButton.vue';
import UAParser from 'ua-parser-js';
import EditProfilePassword from '@/components/profile/edit-profile-password.vue';
import EditProfileEmail from '@/components/profile/edit-profile-email.vue';
import DefaultMaleAvatar from '@/assets/images/default-male.png';
import DefaultFemaleAvatar from '@/assets/images/default-female.png';
import { requestMixin } from '@/mixins/request/request';

export default {
  mixins: [requestMixin],
  name: 'Users',
  components: {
    Datatable,
    BaseButton,
    EditProfilePassword,
    EditProfileEmail,
  },
  data() {
    return {
      success: false,
      failed: false,
      user: {
        id: null,
        name: null,
        email: null,
        office: null,
        role: null,
        hp: null,
        address: null,
        email_changed: false,
        verifyEmail: null,
      },
      recent_login: [],
      baseUrl: process.env.VUE_APP_API_URL,
    };
  },
  created() {
    this.setUser(StorageService.getUser());
    this.fetchAll({
      schema: 'user-logs',
    });
  },
  computed: {
    ...mapGetters({
      getAll: 'main/getAll',
      getOffice: 'offices/getOffice',
      isLoadingOffice: 'offices/getLoading',
      isLoadingUser: 'users/getLoading',
      getUser: 'users/getUser',
      me: 'auth/getUser',
    }),
    getProfileUrl() {
      if (!this.getUser.data?.attributes.avatar) {
        return this.me.sex === 'female'
          ? DefaultFemaleAvatar
          : DefaultMaleAvatar;
      }

      return (
        this.baseUrl + '/api/v1/avatars/' + this.getUser.data?.attributes.avatar
      );
    },
    disabledEdit() {
      return true;
    },
    isLoading() {
      return this.isLoadingOffice || this.isLoadingUser;
    },
    isMember() {
      return this.getOffice.data?.attributes.office_type === 'member';
    },
    officeTypeLabel() {
      return {
        member: 'Mitra Usaha',
        stockist: 'Stockist',
        stockist_center: 'Stockist',
        kantor_pelayanan: 'Kantor Pelayanan',
        pusat: 'Pusat',
      }[this.getOffice.data?.attributes.office_type];
    },
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchOfficeById: 'offices/fetchOfficeById',
      fetchAll: 'main/fetchAll',
      updateUser: 'users/updateUser',
      fetchUser: 'users/fetchUser',
      uploadAvatar: 'users/uploadAvatar',
    }),
    getUserAgent(data) {
      var panggil = new UAParser(data);
      const browser = panggil.getBrowser().name;
      const os = panggil.getOS().name;
      return browser + '/' + os;
      // var result = panggil.getResult();
      // return console.log(result);
    },
    handleChangeFile(event) {
      const data = new FormData();
      data.append('avatar', event.target.files[0]);
      this.uploadAvatar({ id: this.getUser.data.id, payload: data });
    },
    handleProfileUpload() {
      const inputFile = document.getElementById('profile_pic');
      inputFile.click();
    },
    closeModal() {
      this.success = this.failed = false;
      //   location.reload();
    },
    setUser(user) {
      this.user.id = user.uuid;
      this.user.name = user.name;
      this.user.email = user.email;
      this.user.role = user.current_role;
      this.user.hp = user.phone;
      this.user.email_changed = user.email_changed;
      this.user.verifyEmail = user.verify_email;
      this.fetchOfficeById({
        office_id: user.office_id,
      }).then((response) => {
        this.user.office = response.data.data.attributes.name;
        this.user.hp = response.data.data.attributes.phone;
        this.user.address = response.data.data.attributes.address;
      });
      this.fetchUser({
        userId: user.uuid,
        params: {
          // include: 'user-logs',
        },
      }).then((response) => {
        this.recent_login = response.data.data.attributes.user_logs;
      });
    },
    updateProfile() {
      const me = StorageService.getUser();
      const payload = {
        data: {
          type: 'users',
          id: this.user.id,
          attributes: {
            name: this.user.name,
            email: this.user.email,
          },
        },
      };
      this.updateUser(payload).then((response) => {
        if (response) {
          me.name = this.user.name;
          me.email = this.user.email;
          StorageService.saveUser(me);
          this.success = true;
        } else {
          this.failed = true;
        }
      });
    },
    onCreatedUpdateEmail(email) {
      this.user.verifyEmail = email;
    },
    onEmailVerified() {
      this.user.email_changed = true;

      this.fetchOfficeById({
        office_id: StorageService.getUser().office_id,
      });
    },
  },
};
</script>
