const LayoutDefault = () =>
  import(/* webpackChunkName: "admin-gudang" */ '@/layouts/LayoutDefault.vue');
const LayoutData = () =>
  import(/* webpackChunkName: "admin-gudang" */ '@/layouts/LayoutData.vue');
const Dashboard = () =>
  import(
    /* webpackChunkName: "admin-gudang" */ '@/views/pusat/gudang/dashboard/Index.vue'
  );
const Pembelian = () =>
  import(
    /* webpackChunkName: "admin-gudang" */ '@/views/pusat/gudang/pembelian/Index.vue'
  );
const TambahPembelian = () =>
  import(
    /* webpackChunkName: "admin-gudang" */ '@/views/pusat/gudang/pembelian/TambahPembelian.vue'
  );
const PenerimaanBarangMasuk = () =>
  import(
    /* webpackChunkName: "admin-gudang-penerimaan" */ '@/views/pusat/gudang/penerimaan/barang-dari-supplier/Index.vue'
  );
const TambahPenerimaanBarangMasuk = () =>
  import(
    /* webpackChunkName: "admin-gudang-penerimaan" */ '@/views/pusat/gudang/penerimaan/barang-dari-supplier/Tambah.vue'
  );
const PenerimaanReturDariStockist = () =>
  import(
    /* webpackChunkName: "admin-gudang-penerimaan" */ '@/views/pusat/gudang/penerimaan/retur-dari-stockist/Index.vue'
  );
const PenerimaanReturDariSC = () =>
  import(
    /* webpackChunkName: "admin-gudang-penerimaan" */ '@/views/pusat/gudang/penerimaan/retur-dari-stockist-center/Index.vue'
  );
const PenerimaanReturDariKP = () =>
  import(
    /* webpackChunkName: "admin-gudang-penerimaan" */ '@/views/pusat/gudang/penerimaan/retur-dari-pelayanan/Index.vue'
  );
const Stock = () =>
  import(
    /* webpackChunkName: "admin-gudang-stock" */ '@/views/pusat/gudang/stock/Index.vue'
  );
const PilahStock = () =>
  import(
    /* webpackChunkName: "admin-gudang-stock" */ '@/views/pusat/gudang/stock/pilah-stock/Index.vue'
  );
const ManajemenBatch = () =>
  import(
    /* webpackChunkName: "admin-gudang-stock" */ '@/views/pusat/gudang/stock/manajemen-batch/Index.vue'
  );
const LaporanStockGlobal = () =>
  import(
    /* webpackChunkName: "admin-gudang-stock" */ '@/views/pusat/gudang/stock/laporan/LaporanStockGlobal.vue'
  );

// PENGELUARAN
// pengeluaran ke stockist
const PengeluaranBarangKeStockistDikirim = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengeluaran" */ '@/views/pusat/gudang/pengeluaran/barang-ke-stockist-dikirim/Index.vue'
  );
const PengeluaranBarangKeStockistDiambil = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengeluaran" */ '@/views/pusat/gudang/pengeluaran/barang-ke-stockist-diambil/Index.vue'
  );
const DetailPengeluaranBarangKeStockist = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengeluaran" */ '@/views/pusat/gudang/pengeluaran/barang-ke-stockist/DetailPengiriman.vue'
  );
const PengemasanBarangKeStockist = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengeluaran" */ '@/views/pusat/gudang/pengeluaran/barang-ke-stockist/Pengemasan.vue'
  );
// pengeluaran ke sc
const PengeluaranKeSCDikirim = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengeluaran" */ '@/views/pusat/gudang/pengeluaran/barang-ke-sc-dikirim/Index.vue'
  );
const PengeluaranKeSCDiambil = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengeluaran" */ '@/views/pusat/gudang/pengeluaran/barang-ke-sc-diambil/Index.vue'
  );
const DetailPengeluaranBarangKeSC = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengeluaran" */ '@/views/pusat/gudang/pengeluaran/barang-ke-sc/DetailPengiriman.vue'
  );
const PengemasanBarangKeSC = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengeluaran" */ '@/views/pusat/gudang/pengeluaran/barang-ke-sc/Pengemasan.vue'
  );
// pengeluaran ke kp
const PengeluaranBarangKeKP = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengeluaran" */ '@/views/pusat/gudang/pengeluaran/barang-ke-kp/Index.vue'
  );
const DetailPengeluaranBarangKeKP = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengeluaran" */ '@/views/pusat/gudang/pengeluaran/barang-ke-kp/DetailPengiriman.vue'
  );
// pengeluaran ke perusahaan
const PengeluaranBarangKePRSH = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengeluaran" */ '@/views/pusat/gudang/pengeluaran/barang-ke-perusahaan/Index.vue'
  );
// const DetailPengeluaranBarangKePRSH = () =>
//   import(
//     /* webpackChunkName: "admin-gudang" */ '@/views/pusat/gudang/pengeluaran/barang-ke-perusahaan/DetailPengiriman.vue'
//   );
// pengeluaran ke kp
const PengeluaranBarangKeSupplier = () =>
  import(
    /* webpackChunkName: "admin-gudang" */ '@/views/pusat/gudang/pengeluaran/barang-ke-supplier/Index.vue'
  );
// const DetailPengeluaranBarangKeSupplier = () =>
//   import(
//     /* webpackChunkName: "admin-gudang" */ '@/views/pusat/gudang/pengeluaran/barang-ke-supplier/DetailPengiriman.vue'
//   );
const PengeluaranRestockBarangKeStockistDikirim = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengeluaran" */ '@/views/pusat/gudang/pengeluaran/restock-barang-ke-stockist-dikirim/Index.vue'
  );
const PengeluaranRestockBarangKeStockistDiambil = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengeluaran" */ '@/views/pusat/gudang/pengeluaran/restock-barang-ke-stockist-diambil/Index.vue'
  );
const PengemasanBarangKeKP = () =>
  import(
    /* webpackChunkName: "admin-gudang" */ '@/views/pusat/gudang/pengeluaran/barang-ke-kp/Pengemasan.vue'
  );

// PENGIRIMAN
// pengiriman ke stockist
const PengirimanBarangkeStockistDikirim = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengiriman" */ '@/views/pusat/gudang/pengiriman/barang-ke-stockist-dikirim/Index.vue'
  );
const PengirimanBarangkeStockistDiambil = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengiriman" */ '@/views/pusat/gudang/pengiriman/barang-ke-stockist-diambil/Index.vue'
  );
const TambahPengirimanBarangkeStockist = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengiriman" */ '@/views/pusat/gudang/pengiriman/barang-ke-stockist/Tambah.vue'
  );
// pengiriman ke SC
const PengirimanBarangKeSCDiambil = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengiriman" */ '@/views/pusat/gudang/pengiriman/barang-ke-sc-diambil/Index.vue'
  );
const PengirimanBarangKeSCDikirim = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengiriman" */ '@/views/pusat/gudang/pengiriman/barang-ke-sc-dikirim/Index.vue'
  );
const TambahPengirimanBarangKeSC = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengiriman" */ '@/views/pusat/gudang/pengiriman/barang-ke-sc/Tambah.vue'
  );

// pengiriman ke KP
const PengirimanBarangkeKP = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengiriman" */ '@/views/pusat/gudang/pengiriman/barang-ke-kp/Index.vue'
  );
const TambahPengirimanBarangkeKP = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengiriman" */ '@/views/pusat/gudang/pengiriman/barang-ke-kp/Tambah.vue'
  );
const PengirimanReturKeSupplier = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengiriman" */ '@/views/pusat/gudang/pengiriman/retur-ke-supplier/Index.vue'
  );
const TambahPengirimanReturKeSupplier = () =>
  import(
    /* webpackChunkName: "admin-gudang-pengiriman" */ '@/views/pusat/gudang/pengiriman/retur-ke-supplier/Tambah.vue'
  );
// Susulan ke stockist
const SusulanBarangKeStockist = () =>
  import(
    /* webpackChunkName: "admin-gudang-susulan" */ '@/views/pusat/gudang/susulan/barang-ke-stockist/Index.vue'
  );
const TambahSusulanBarangKeStockist = () =>
  import(
    /* webpackChunkName: "admin-gudang-susulan" */ '@/views/pusat/gudang/susulan/barang-ke-stockist/Tambah.vue'
  );
const PengemasanSusulanBarangKeStockist = () =>
  import(
    /* webpackChunkName: "admin-gudang-susulan" */ '@/views/pusat/gudang/susulan/barang-ke-stockist/Detail.vue'
  );

// Susulan ke stockist center
const SusulanBarangKeSC = () =>
  import(
    /* webpackChunkName: "admin-gudang-susulan" */ '@/views/pusat/gudang/susulan/barang-ke-sc/Index.vue'
  );
const TambahSusulanBarangKeSC = () =>
  import(
    /* webpackChunkName: "admin-gudang-susulan" */ '@/views/pusat/gudang/susulan/barang-ke-sc/Tambah.vue'
  );
const PengemasanSusulanBarangKeSC = () =>
  import(
    /* webpackChunkName: "admin-gudang-susulan" */ '@/views/pusat/gudang/susulan/barang-ke-sc/Detail.vue'
  );

// Susulan ke kp
const SusulanBarangKeKP = () =>
  import(
    /* webpackChunkName: "admin-gudang-susulan" */ '@/views/pusat/gudang/susulan/barang-ke-kp/Index.vue'
  );
const TambahSusulanBarangKeKP = () =>
  import(
    /* webpackChunkName: "admin-gudang-susulan" */ '@/views/pusat/gudang/susulan/barang-ke-kp/Tambah.vue'
  );
const PengemasanSusulanBarangKeKP = () =>
  import(
    /* webpackChunkName: "admin-gudang-susulan" */ '@/views/pusat/gudang/susulan/barang-ke-kp/Detail.vue'
  );

// Setup
const Setup = () =>
  import(
    /* webpackChunkName: "admin-gudang" */ '@/views/pusat/gudang/setup/Index.vue'
  );

// Restock
const RestockKP = () =>
  import(
    /* webpackChunkName: "admin-gudang" */ '@/views/pusat/gudang/restock/pusat-ke-kp/Index.vue'
  );
const TambahRestockKP = () =>
  import(
    /* webpackChunkName: "admin-gudang" */ '@/views/pusat/gudang/restock/pusat-ke-kp/Tambah.vue'
  );

let root = '/gudang';

export default [
  {
    path: root + '/',
    component: Dashboard,
    meta: {
      auth: true,
      title: 'Dashboard | Gudang',
      layout: LayoutDefault,
    },
  },
  {
    path: root + '/pembelian/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Pembelian',
    },
    children: [
      {
        path: '',
        component: Pembelian,
        name: 'pembelian',
        meta: {
          auth: true,
          title: 'Pembelian | Gudang',
          layout: LayoutDefault,
        },
      },
      {
        path: 'tambah-pembelian',
        component: TambahPembelian,
        name: 'pembelian.tambah',
        meta: {
          auth: true,
          title: 'Tambah Pembelian | Gudang',
          layout: LayoutDefault,
          breadcrumb: 'Tambah Pembelian',
        },
      },
      {
        path: ':id',
        component: TambahPembelian,
        name: 'pembelian.edit',
        meta: {
          auth: true,
          title: 'Tambah Pembelian | Gudang',
          layout: LayoutDefault,
          breadcrumb: 'Edit Pembelian',
        },
      },
    ],
  },
  {
    path: root + '/penerimaan/',
    component: LayoutData,
    children: [
      {
        path: 'penerimaan-barang-dari-supplier/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Penerimaan Barang dari Supplier',
        },
        children: [
          {
            path: '',
            component: PenerimaanBarangMasuk,
            meta: {
              auth: true,
              title: 'Penerimaan Barang Masuk | Gudang',
              layout: LayoutDefault,
            },
          },
          {
            path: 'tambah-barang-masuk',
            component: TambahPenerimaanBarangMasuk,
            meta: {
              auth: true,
              title: 'Tambah Barang Masuk | Gudang',
              layout: LayoutDefault,
              breadcrumb: 'Tambah Penerimaan Barang',
            },
          },
          {
            path: ':id',
            component: TambahPenerimaanBarangMasuk,
            meta: {
              auth: true,
              title: 'Tambah Barang Masuk | Gudang',
              layout: LayoutDefault,
              breadcrumb: 'Tambah Penerimaan Barang',
            },
          },
        ],
      },
      {
        path: 'penerimaan-retur-dari-stockist/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Penerimaan Retur dari Stockist',
        },
        children: [
          {
            path: '',
            component: PenerimaanReturDariStockist,
            meta: {
              auth: true,
              title: 'Penerimaan Barang Masuk | Gudang',
              layout: LayoutDefault,
            },
          },
        ],
      },
      {
        path: 'penerimaan-retur-dari-stockist-center/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Penerimaan Retur dari Stockist Center',
        },
        children: [
          {
            path: '',
            component: PenerimaanReturDariSC,
            meta: {
              auth: true,
              title: 'Penerimaan Barang Masuk | Gudang',
              layout: LayoutDefault,
            },
          },
        ],
      },
      {
        path: 'penerimaan-retur-dari-kantor-pelayanan/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Penerimaan Retur dari Kantor Pelayanan',
        },
        children: [
          {
            path: '',
            component: PenerimaanReturDariKP,
            meta: {
              auth: true,
              title: 'Penerimaan Barang Masuk | Gudang',
              layout: LayoutDefault,
            },
          },
        ],
      },
    ],
  },
  {
    path: root + '/pengeluaran/',
    component: LayoutData,
    children: [
      {
        path: 'barang-ke-stockist-dikirim/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Pengeluaran Barang ke Stockist',
        },
        children: [
          {
            path: '',
            component: PengeluaranBarangKeStockistDikirim,
            meta: {
              auth: true,
              title: 'Pengeluaran Barang ke Stockist Dikirim | Gudang',
              layout: LayoutDefault,
            },
          },
          {
            path: ':id/',
            component: LayoutData,
            meta: {
              breadcrumb: 'Detail Pengeluaran Barang ke Stockist Dikirim',
            },
            children: [
              {
                path: '',
                component: DetailPengeluaranBarangKeStockist,
                name: 'gudang.pengeluaran.barang-ke-stockist-dikirim.detail',
                meta: {
                  auth: true,
                  title: 'Detail Pengeluaran Barang ke Stockist Dikirim | Gudang',
                  layout: LayoutDefault,
                },
                props: {
                  indexPath: '/gudang/pengeluaran/barang-ke-stockist-dikirim'
                }
              },
              {
                path: 'pengemasan',
                component: PengemasanBarangKeStockist,
                meta: {
                  auth: true,
                  title: 'Pengemasan Barang | Gudang',
                  layout: LayoutDefault,
                  breadcrumb: 'Pengemasan Barang ke Stockist Dikirim',
                },
                props: {
                  indexPath: '/gudang/pengeluaran/barang-ke-stockist-dikirim'
                }
              }
            ],
          },
        ],
      },
      {
        path: 'barang-ke-stockist-diambil/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Pengeluaran Barang ke Stockist',
        },
        children: [
          {
            path: '',
            component: PengeluaranBarangKeStockistDiambil,
            meta: {
              auth: true,
              title: 'Pengeluaran Barang ke Stockist Diambil | Gudang',
              layout: LayoutDefault,
            },
          },
          {
            path: ':id/',
            component: LayoutData,
            meta: {
              breadcrumb: 'Detail Pengeluaran Barang ke Stockist Diambil',
            },
            children: [
              {
                path: '',
                component: DetailPengeluaranBarangKeStockist,
                name: 'gudang.pengeluaran.barang-ke-stockist-diambil.detail',
                meta: {
                  auth: true,
                  title: 'Detail Pengeluaran Barang ke Stockist Diambil | Gudang',
                  layout: LayoutDefault,
                },
                props: {
                  indexPath: '/gudang/pengeluaran/barang-ke-stockist-diambil'
                }
              },
              {
                path: 'pengemasan',
                component: PengemasanBarangKeStockist,
                meta: {
                  auth: true,
                  title: 'Pengemasan Barang | Gudang',
                  layout: LayoutDefault,
                  breadcrumb: 'Pengemasan Barang ke Stockist Diambil',
                },
                props: {
                  indexPath: '/gudang/pengeluaran/barang-ke-stockist-diambil'
                }
              },
            ],
          },
        ],
      },
      {
        path: 'barang-ke-sc-dikirim/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Pengeluaran Barang ke SC',
        },
        children: [
          {
            path: '',
            component: PengeluaranKeSCDikirim,
            meta: {
              auth: true,
              title: 'Pengeluaran Barang ke SC Dikirim | Gudang',
              layout: LayoutDefault,
            },
          },
          {
            path: ':id/',
            component: LayoutData,
            meta: {
              breadcrumb: 'Detail Pengeluaran Barang ke SC Dikirim',
            },
            children: [
              {
                path: '',
                name: 'gudang.pengeluaran.barang-ke-sc-dikirim.detail',
                component: DetailPengeluaranBarangKeSC,
                meta: {
                  auth: true,
                  title: 'Detail Pengeluaran Barang SC Dikirim | Gudang',
                  layout: LayoutDefault
                },
                props: {
                  indexPath: '/gudang/pengeluaran/barang-ke-sc-dikirim'
                }
              },
              {
                path: 'pengemasan',
                component: PengemasanBarangKeSC,
                meta: {
                  auth: true,
                  title: 'Pengemasan Barang | Gudang',
                  layout: LayoutDefault,
                  breadcrumb: 'Pengemasan Barang ke SC Dikirim',
                },
                props: {
                  indexPath: '/gudang/pengeluaran/barang-ke-sc-dikirim'
                }
              },
            ],
          },
        ],
      },
      {
        path: 'barang-ke-sc-diambil/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Pengeluaran Barang ke SC',
        },
        children: [
          {
            path: '',
            component: PengeluaranKeSCDiambil,
            meta: {
              auth: true,
              title: 'Pengeluaran Barang ke SC Diambil | Gudang',
              layout: LayoutDefault,
            },
          },
          {
            path: ':id/',
            component: LayoutData,
            meta: {
              breadcrumb: 'Detail Pengeluaran Barang ke SC Diambil',
            },
            children: [
              {
                path: '',
                component: DetailPengeluaranBarangKeSC,
                name: 'gudang.pengeluaran.barang-ke-sc-diambil.detail',
                meta: {
                  auth: true,
                  title: 'Detail Pengeluaran Barang SC Diambil | Gudang',
                  layout: LayoutDefault
                },
                props: {
                  indexPath: '/gudang/pengeluaran/barang-ke-sc-diambil'
                },
              },
              {
                path: 'pengemasan',
                component: PengemasanBarangKeSC,
                meta: {
                  auth: true,
                  title: 'Pengemasan Barang | Gudang',
                  layout: LayoutDefault,
                  breadcrumb: 'Pengemasan Barang ke SC Diambil',
                },
                props: {
                  indexPath: '/gudang/pengeluaran/barang-ke-sc-diambil'
                },
              },
            ],
          },
        ],
      },
      {
        path: 'barang-ke-kp/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Pengeluaran Barang ke KP',
        },
        children: [
          {
            path: '',
            component: PengeluaranBarangKeKP,
            name: 'gudang.pengeluaran.barang-ke-kp',
            meta: {
              auth: true,
              title: 'Pengeluaran Barang ke KP | Gudang',
              layout: LayoutDefault,
            },
          },
          {
            path: ':id/',
            component: LayoutData,
            meta: {
              breadcrumb: 'Detail Pengeluaran Barang ke KP',
            },
            children: [
              {
                path: '',
                component: DetailPengeluaranBarangKeKP,
                name: 'gudang.pengeluaran.barang-ke-kp.detail',
                meta: {
                  auth: true,
                  title: 'Detail Pengeluaran Barang | Gudang',
                  layout: LayoutDefault,
                },
              },
              {
                path: 'pengemasan',
                component: PengemasanBarangKeKP,
                name: 'gudang.pengeluaran.barang-ke-kp.pengemasan',
                meta: {
                  auth: true,
                  title: 'Pengemasan Barang | Gudang',
                  layout: LayoutDefault,
                  breadcrumb: 'Pengemasan Barang ke KP',
                },
              },
            ],
          },
        ],
      },
      {
        path: 'barang-ke-perusahaan/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Pengeluaran Barang ke Perusahaan',
        },
        children: [
          {
            path: '',
            name: 'gudang.pengeluaran.ke-perusahaan',
            component: PengeluaranBarangKePRSH,
            meta: {
              auth: true,
              title: 'Pengeluaran Barang ke Perusahaan | Gudang',
              layout: LayoutDefault,
            },
          },
        ],
      },
      {
        path: 'barang-ke-supplier/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Pengeluaran Barang ke Supplier',
        },
        children: [
          {
            path: '',
            name: 'gudang.pengeluaran.ke-supplier',
            component: PengeluaranBarangKeSupplier,
            meta: {
              auth: true,
              title: 'Pengeluaran Barang ke Supplier | Gudang',
              layout: LayoutDefault,
            },
          },
        ],
      },
      {
        path: 'restock-barang-ke-stockist-dikirim/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Pengeluaran Restock Barang ke Stockist',
        },
        children: [
          {
            path: '',
            component: PengeluaranRestockBarangKeStockistDikirim,
            meta: {
              auth: true,
              title: 'Pengeluaran Restock Barang ke Stockist Dikirim | Gudang',
              layout: LayoutDefault,
            },
          },
          {
            path: ':id/',
            component: LayoutData,
            meta: {
              breadcrumb: 'Detail Pengeluaran Restock Barang ke Stockist Dikirim',
            },
            children: [
              {
                path: '',
                component: DetailPengeluaranBarangKeStockist,
                name: 'gudang.pengeluaran.restock-barang-ke-stockist-dikirim.detail',
                meta: {
                  auth: true,
                  title: 'Detail Pengeluaran Restock Barang ke Stockist Dikirim | Gudang',
                  layout: LayoutDefault,
                },
                props: {
                  indexPath: '/gudang/pengeluaran/restock-barang-ke-stockist-dikirim'
                }
              },
              {
                path: 'pengemasan',
                component: PengemasanBarangKeStockist,
                meta: {
                  auth: true,
                  title: 'Pengemasan Barang | Gudang',
                  layout: LayoutDefault,
                  breadcrumb: 'Pengemasan Barang ke Stockist Dikirim',
                },
                props: {
                  indexPath: '/gudang/pengeluaran/restock-barang-ke-stockist-dikirim'
                }
              }
            ],
          },
        ],
      },
      {
        path: 'restock-barang-ke-stockist-diambil/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Pengeluaran Restock Barang ke Stockist',
        },
        children: [
          {
            path: '',
            component: PengeluaranRestockBarangKeStockistDiambil,
            meta: {
              auth: true,
              title: 'Pengeluaran Restock Barang ke Stockist Diambil | Gudang',
              layout: LayoutDefault,
            },
          },
          {
            path: ':id/',
            component: LayoutData,
            meta: {
              breadcrumb: 'Detail Pengeluaran Restock Barang ke Stockist Diambil',
            },
            children: [
              {
                path: '',
                component: DetailPengeluaranBarangKeStockist,
                name: 'gudang.pengeluaran.restock-barang-ke-stockist-diambil.detail',
                meta: {
                  auth: true,
                  title: 'Detail Pengeluaran Restock Barang ke Stockist Diambil | Gudang',
                  layout: LayoutDefault,
                },
                props: {
                  indexPath: '/gudang/pengeluaran/restock-barang-ke-stockist-diambil'
                }
              },
              {
                path: 'pengemasan',
                component: PengemasanBarangKeStockist,
                meta: {
                  auth: true,
                  title: 'Pengemasan Barang | Gudang',
                  layout: LayoutDefault,
                  breadcrumb: 'Pengemasan Barang ke Stockist Diambil',
                },
                props: {
                  indexPath: '/gudang/pengeluaran/restock-barang-ke-stockist-diambil'
                }
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: root + '/pengiriman/',
    component: LayoutData,
    children: [
      {
        path: 'barang-ke-stockist-dikirim/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Pengiriman Barang ke Stockist Dikirim',
        },
        children: [
          {
            path: '',
            component: PengirimanBarangkeStockistDikirim,
            meta: {
              auth: true,
              title: 'Pengiriman Barang ke Stockist Dikirim | Gudang',
              layout: LayoutDefault,
            },
          },
          {
            path: ':id',
            component: TambahPengirimanBarangkeStockist,
            name: 'gudang.pengiriman.barang-ke-stockist-dikirim.detail',
            meta: {
              auth: true,
              title: 'Tambah Pengiriman Barang ke Stockist Dikirim | Gudang',
              layout: LayoutDefault,
            },
            props: {
              indexPath: '/gudang/pengiriman/barang-ke-stockist-dikirim'
            }
          },
        ],
      },
      {
        path: 'barang-ke-stockist-diambil/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Pengiriman Barang ke Stockist Diambil',
        },
        children: [
          {
            path: '',
            component: PengirimanBarangkeStockistDiambil,
            meta: {
              auth: true,
              title: 'Pengiriman Barang ke Stockist Diambil | Gudang',
              layout: LayoutDefault,
            },
          },
          {
            path: ':id',
            component: TambahPengirimanBarangkeStockist,
            name: 'gudang.pengiriman.barang-ke-stockist-diambil.detail',
            meta: {
              auth: true,
              title: 'Tambah Pengiriman Barang ke Stockist Diambil | Gudang',
              layout: LayoutDefault,
            },
            props: {
              indexPath: '/gudang/pengiriman/barang-ke-stockist-diambil'
            },
          }
        ],
      },
      {
        path: 'barang-ke-sc-diambil/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Pengiriman Barang ke SC Diambil',
        },
        children: [
          {
            path: '',
            component: PengirimanBarangKeSCDiambil,
            meta: {
              auth: true,
              title: 'Pengiriman Barang ke SC Diambil | Gudang',
              layout: LayoutDefault,
            },
          },
          {
            path: ':id',
            component: TambahPengirimanBarangKeSC,
            name: 'gudang.pengiriman.barang-ke-sc-diambil.detail',
            meta: {
              auth: true,
              title: 'Tambah Pengiriman Barang ke SC Diambil | Gudang',
              layout: LayoutDefault,
            },
            props: {
              indexPath: '/gudang/pengiriman/barang-ke-sc-diambil'
            },
          },
        ],
      },
      {
        path: 'barang-ke-sc-dikirim/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Pengiriman Barang ke SC Dikirim',
        },
        children: [
          {
            path: '',
            component: PengirimanBarangKeSCDikirim,
            meta: {
              auth: true,
              title: 'Pengiriman Barang ke SC Dikirim | Gudang',
              layout: LayoutDefault,
            },
          },
          {
            path: ':id',
            component: TambahPengirimanBarangKeSC,
            name: 'gudang.pengiriman.barang-ke-sc-dikirim.detail',
            meta: {
              auth: true,
              title: 'Tambah Pengiriman Barang ke SC Dikirim | Gudang',
              layout: LayoutDefault,
            },
            props: {
              indexPath: '/gudang/pengiriman/barang-ke-sc-dikirim'
            },
          },
        ],
      },
      {
        path: 'barang-ke-kp/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Pengiriman Barang ke KP',
        },
        children: [
          {
            path: '',
            component: PengirimanBarangkeKP,
            name: 'gudang.pengiriman.barang-ke-kp',
            meta: {
              auth: true,
              title: 'Pengiriman Barang ke KP | Gudang',
              layout: LayoutDefault,
            },
          },
          {
            path: ':id',
            component: TambahPengirimanBarangkeKP,
            name: 'gudang.pengiriman.barang-ke-kp.detail',
            meta: {
              auth: true,
              title: 'Tambah Pengiriman Barang ke KP | Gudang',
              layout: LayoutDefault,
            },
          },
        ],
      },
      {
        path: 'retur-ke-supplier/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Pengiriman Retur ke Supplier',
        },
        children: [
          {
            path: '',
            component: PengirimanReturKeSupplier,
            name: 'index.send.retur.supplier',
            meta: {
              auth: true,
              title: 'Pengiriman Retur ke Supplier | Gudang',
              layout: LayoutDefault,
            },
          },
          {
            path: ':id',
            component: TambahPengirimanReturKeSupplier,
            name: 'edit.send.retur.supplier',
            meta: {
              auth: true,
              title: 'Tambah Pengiriman Retur ke Supplier | Gudang',
              layout: LayoutDefault,
              breadcrumb: 'Tambah Pengiriman Retur ke Supplier',
            },
          },
        ],
      },
    ],
  },
  {
    path: root + '/stock/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Stock',
    },
    children: [
      {
        path: 'stocks',
        component: Stock,
        meta: {
          auth: true,
          title: 'Stock | Gudang',
          layout: LayoutDefault,
        },
      },
      {
        path: 'laporan-stock-global',
        component: LaporanStockGlobal,
        meta: {
          auth: true,
          title: 'Laporan Stock Global | Gudang',
          layout: LayoutDefault,
        },
      },
      {
        path: 'pilah-stock',
        component: PilahStock,
        meta: {
          auth: true,
          title: 'Pilah Stock | Gudang',
          layout: LayoutDefault,
        },
      },
      {
        path: 'batch',
        component: ManajemenBatch,
        meta: {
          auth: true,
          title: 'Manajemen Batch | Gudang',
          layout: LayoutDefault,
        },
      },
    ],
  },
  {
    path: root + '/setup/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Setup',
    },
    children: [
      {
        path: '',
        component: Setup,
        meta: {
          auth: true,
          title: 'Setup | Gudang',
          layout: LayoutDefault,
        },
      },
    ],
  },
  {
    path: root + '/susulan/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Susulan',
    },
    children: [
      {
        path: 'barang-ke-stockist/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Pengiriman Susulan ke Stockist',
        },
        children: [
          {
            path: '',
            component: SusulanBarangKeStockist,
            name: 'gudang.susulan.barang-ke-stockist',
            meta: {
              auth: true,
              title: 'Pengiriman Susulan ke Stockist | Gudang',
              layout: LayoutDefault,
            },
          },
          {
            path: ':id',
            component: TambahSusulanBarangKeStockist,
            name: 'gudang.susulan.barang-ke-stockist.tambah',
            meta: {
              auth: true,
              title: 'Tambah Pengiriman Susulan ke Stockist | Gudang',
              layout: LayoutDefault,
              breadcrumb: 'Pengiriman Susulan ke Stockist',
            },
          },
          {
            path: ':id/pengemasan',
            component: PengemasanSusulanBarangKeStockist,
            meta: {
              auth: true,
              title: 'Pengemasan Susulan ke Stockist | Gudang',
              layout: LayoutDefault,
              breadcrumb: 'Pengemasan Susulan ke Stockist',
            },
          },
        ],
      },
      {
        path: 'barang-ke-sc/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Pengiriman Susulan ke SC',
        },
        children: [
          {
            path: '',
            component: SusulanBarangKeSC,
            meta: {
              auth: true,
              title: 'Pengiriman Susulan ke SC | Gudang',
              layout: LayoutDefault,
            },
          },
          {
            path: ':id',
            component: TambahSusulanBarangKeSC,
            meta: {
              auth: true,
              title: 'Tambah Pengiriman Susulan ke SC | Gudang',
              layout: LayoutDefault,
              breadcrumb: 'Pengiriman Susulan ke SC',
            },
          },
          {
            path: ':id/pengemasan',
            component: PengemasanSusulanBarangKeSC,
            meta: {
              auth: true,
              title: 'Pengemasan Susulan ke SC | Gudang',
              layout: LayoutDefault,
              breadcrumb: 'Pengemasan Susulan ke SC',
            },
          },
        ],
      },
      {
        path: 'barang-ke-kp/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Pengiriman Susulan ke KP',
        },
        children: [
          {
            path: '',
            component: SusulanBarangKeKP,
            meta: {
              auth: true,
              title: 'Pengiriman Susulan ke KP | Gudang',
              layout: LayoutDefault,
            },
          },
          {
            path: ':id',
            component: TambahSusulanBarangKeKP,
            meta: {
              auth: true,
              title: 'Tambah Pengiriman Susulan ke KP | Gudang',
              layout: LayoutDefault,
              breadcrumb: 'Detail Pengiriman Susulan ke KP',
            },
          },
          {
            path: ':id/pengemasan',
            component: PengemasanSusulanBarangKeKP,
            meta: {
              auth: true,
              title: 'Pengemasan Susulan ke KP | Gudang',
              layout: LayoutDefault,
              breadcrumb: 'Pengemasan Susulan ke KP',
            },
          },
        ],
      },
    ],
  },
  {
    path: root + '/restock/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Restock',
    },
    children: [
      {
        path: 'pusat-ke-kp/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Restock KP',
        },
        children: [
          {
            path: '',
            component: RestockKP,
            name: 'restock.kp',
            meta: {
              auth: true,
              title: 'Restock KP | Gudang',
              layout: LayoutDefault,
            },
          },
          {
            path: 'tambah',
            component: TambahRestockKP,
            name: 'restock.kp.tambah',
            meta: {
              auth: true,
              title: 'Tambah Restock KP | Gudang',
              layout: LayoutDefault,
              breadcrumb: 'Restock KP',
            },
          },
          {
            path: ':id',
            component: TambahRestockKP,
            meta: {
              auth: true,
              title: 'Tambah Restock KP | Gudang',
              layout: LayoutDefault,
              breadcrumb: 'Restock KP',
            },
          },
        ],
      },
    ],
  },
];
