<template>
  <base-modal :showing="visible" :size="size" @close="$emit('close')" :show-close="closable">
    <div :class="['space-y-4', center ? 'text-center' : '']">
      <template v-if="icon">
        <div
          v-if="iconType === 'badge'"
          :class="['flex mx-auto h-12 w-12 items-center justify-center rounded-full', badgeColor]"
        >
          <Icon :icon="iconName" :class="['w-6 h-6 mx-auto', iconColor]" />
        </div>
        <Icon v-else :icon="iconName" :class="['w-20 h-20 mx-auto', iconColor]" />
      </template>
      <div class="space-y-1">
        <p v-if="title" class="text-lg font-medium leading-6 text-gray-900">{{ title }}</p>
        <slot name="message">
          <p :class="{ 'text-gray-500': title }">{{ message }}</p>
        </slot>
      </div>
      <slot name="content" />
      <template v-if="input">
        <p class="font-bold">{{ code }}</p>
        <base-input
          :shadow="false"
          fullwidth
          :placeholder="code"
          v-model="codeInput"
        />
      </template>
      <div :class="['grid gap-2', withCancel ? 'grid-cols-2' : '']">
        <base-button
          :disabled="input && codeInput !== code"
          fullwidth
          :color="type"
          @click="$emit('confirmed')"
          >{{ confirmText }}</base-button
        >
        <base-button v-if="withCancel" fullwidth color="white" @click="$emit('close')"
          >{{ cancelText }}</base-button
        >
      </div>
    </div>
  </base-modal>
</template>

<script>
import BaseModal from './BaseModal.vue';

export default {
  components: {
    BaseModal,
  },
  props: {
    message: String,
    visible: Boolean,
    title: String,
    icon: {
      type: Boolean,
      default: true,
    },
    iconType: {
      type: String,
      default: 'icon'
    },
    customIconName: String,
    center: {
      type: Boolean,
      default: true,
    },
    input: Boolean,
    code: String,
    size: {
      type: String,
      default: 'max-w-sm',
    },
    type: {
      type: String,
      default: 'danger',
    },
    withCancel: {
      type: Boolean,
      default: true
    },
    confirmText: {
      type: String,
      default: 'Yakin'
    },
    cancelText: {
      type: String,
      default: 'Batal'
    },
    closable: {
      type: Boolean,
      default: true
    }
  },
  emits: ['close', 'confirmed'],
  data() {
    return {
      codeInput: null,
    };
  },
  computed: {
    iconName() {
      if (this.customIconName) {
        return this.customIconName
      }

      const opt = this.iconType === 'icon' ? {
        success: 'heroicons:check-circle',
        danger: 'heroicons:exclamation-circle',
        warning: 'heroicons:information-circle'
      } : {
        success: 'heroicons:check',
        danger: 'heroicons:x-mark',
        warning: 'heroicons:information-circle'
      }

      return opt[this.type]
    },
    iconColor() {
      const opt = this.iconType === 'icon' ? {
        success: 'text-green-500',
        danger: 'text-red-500',
        warning: 'text-yellow-500'
      } : {
        success: 'text-green-600',
        danger: 'text-red-600',
        warning: 'text-yellow-600'
      }

      return opt[this.type]
    },
    badgeColor() {
      return {
        success: 'bg-green-100',
        danger: 'bg-red-100',
        warning: 'bg-yellow-100'
      }[this.type]
    }
  }
};
</script>
