<template>
  <div
    ref="parent"
    :style="{ 'max-width': `${50 + 2 * visibleIndexCount}vw` }"
    style="flex-shrink: 1; flex-grow: 0"
    class="flex h-full items-center font-light"
    v-click-outside="onClose"
  >
    <button class="px-2" @click="prev" v-if="currentIndex != 0">
      <Icon class="h-5 w-5" icon="heroicons:chevron-double-left-20-solid" />
    </button>
    <template v-for="item in visibleItems">
      <div
        class="flex mx-1 h-full items-center"
        @click="toggle"
        :key="item.id"
        ref="visibleItem"
      >
        <template v-if="item.attributes.type != 'group'">
          <router-link
            :to="
              item.attributes.url[0] == '/'
                ? item.attributes.url
                : '/' + item.attributes.url
            "
            :exact-active-class="'font-semibold'"
            class="flex mx-1 rounded text-sm"
          >
            {{ item.attributes.name }}
          </router-link>
        </template>

        <template v-else>
          <div
            style="flex-shrink: 1"
            id="tree-dropdown"
            class="flex h-full items-center"
          >
            <div
              :class="[]"
            >
              <div class="inline-flex cursor-pointer items-center rounded" @click="onMouseEnter(item)">
                <p class="text-sm">
                  {{ item.attributes.name }}
                </p>
                <Icon icon="heroicons:chevron-down-20-solid" class="h-4 w-4" />
              </div>
              <div>
                <transition
                  enter-active-class="transition duration-100 ease-out"
                  enter-from-class="transform scale-95 opacity-0"
                  enter-to-class="transform scale-100 opacity-100"
                  leave-active-class="transition duration-100 ease-in"
                  leave-to-class="transform scale-95 opacity-0"
                >
                  <div
                    class="relative z-50"
                    v-if="isOpen && item.id == selectedItem.id"
                  >
                    <div
                      class="focus:outline-none absolute right-0 z-50 mt-3 w-72 origin-top-right rounded-md bg-white py-3 shadow-lg ring-1 ring-black ring-opacity-5"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="user-menu-button"
                      tabindex="-1"
                    >
                      <div
                        v-for="currentMenu in childrenMenus(item.id)"
                        :key="currentMenu.id"
                        class="relative space-y-2 px-3"
                      >
                        <router-link
                          :to="
                            currentMenu.attributes.url[0] == '/'
                              ? currentMenu.attributes.url
                              : '/' + currentMenu.attributes.url
                          "
                          :active-class="'font-semibold'"
                          class="group flex h-full items-center gap-x-2 rounded-md p-4 text-sm text-gray-900"
                          :class="defaultLink"
                        >
                          <div class="flex w-full items-center justify-between">
                            <div class="text-sm">
                              {{ currentMenu.attributes.name }}
                              <p class="text-xs font-light text-gray-400 group-hover:text-white mt-1">
                                {{ currentMenu.attributes.description }}
                              </p>
                            </div>
                            <div v-if="subChild(currentMenu).length">
                              <Icon icon="heroicons:chevron-right-20-solid" />
                            </div>
                          </div>
                          <ul
                            v-if="subChild(currentMenu).length"
                            style="right: -100%; top: 0"
                            class="focus:outline-none absolute right-0 z-50 w-72 origin-top-right rounded-md bg-white py-3 text-gray-900 shadow-lg ring-1 ring-black ring-opacity-5"
                          >
                            <router-link
                              class="block p-4 text-center"
                              :to="
                                child.attributes.url[0] == '/'
                                  ? child.attributes.url
                                  : '/' + child.attributes.url
                              "
                              :class="defaultLink"
                              :active-class="activeColor"
                              v-for="child in subChild(currentMenu)"
                              :key="child.id"
                            >
                              {{ child.attributes.name }}
                            </router-link>
                          </ul>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
    <button
      @click="next"
      class="px-2"
      v-if="currentIndex < items.length - visibleIndexCount"
    >
      <Icon class="h-5 w-5" icon="heroicons:chevron-double-right-20-solid" />
    </button>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TreeMenu',
  props: {
    items: {
      type: Array,
    },
    textColor: {
      type: String,
    },
    borderColor: {
      type: String,
    },
    activeColor: {
      type: String,
    },
    defaultLink: {
      type: String,
    },
    subfolderActive: {
      type: String,
    },
    selectedMenu: {
      type: [Object, String],
    },
  },

  data() {
    return {
      isOpen: false,
      value: '',
      baseUrl: process.env.VUE_APP_API_URL,
      selectedItem: {},
      selectedChildren: {},
      currentIndex: 0,
      visibleIndexCount: 99,
    };
  },

  mounted() {
    this.value = this.getMenuId;
    this.calculateMenu();
    // window.addEventListener('resize', this.calculateMenu);
  },
  unmounted() {
    // window.removeEventListener('resize', this.calculateMenu);
  },

  created() {
    const handleEscape = () => {
      this.isOpen = false;
    };

    this.setEscape(handleEscape);

    this.$router.afterEach(() => {
      this.isOpen = false
    })
  },

  computed: {
    ...mapGetters({
      getMenuId: 'auth/getMenuId',
      getMenus: 'menus/getMenus',
    }),
    visibleItems() {
      const items = this.items.slice(
        this.currentIndex,
        this.currentIndex + this.visibleIndexCount
      );
      return JSON.parse(JSON.stringify(items));
    },
  },

  methods: {
    calculateMenu() {
      const interval = setInterval(() => {
        if (this.$refs.visibleItem) {
          const visibleCount = this.$refs.visibleItem
            .map((el) => {
              return this.checkOverflow(el);
            })
            .filter((item) => item === false).length;

          if (this.items.length == visibleCount) {
            this.visibleIndexCount = visibleCount;
          } else {
            this.visibleIndexCount = visibleCount - 2;
          }
          clearInterval(interval);
        }
      });
    },
    subChild(item) {
      return this.getMenus.data.filter(
        (it) => it.attributes.parent_id == item.id
      );
    },
    checkOverflow(el) {
      // lebar window
      const documentWidth = document.documentElement.offsetWidth;
      if (!el) {
        console.error('el not found', el);
        return;
      }
      const box = el.getBoundingClientRect();

      if (box.left < 0 || box.right > documentWidth - 200) {
        el.style.border = '1px solid red';
        return true;
      }
      return false;
    },
    next() {
      this.currentIndex = this.currentIndex + 1;
    },
    prev() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    },
    onMouseEnter(item) {
      if (this.isOpen && item.id === this.selectedItem.id) {
        this.isOpen = false
      } else {
        this.isOpen = true;
        this.selectedItem = item;
      }
    },
    onMouseLeave() {
      this.isOpen = false;
    },
    onClickItem(data) {
      this.$router.push(data.url);
    },
    onClose() {
      this.isOpen = false;
    },
    toggle() {
      if (this.isFolder) {
        this.isOpen = !this.isOpen;
      }
    },
    childrenMenus(menuId) {
      return this.getMenus.data.filter(
        (it) => it.attributes.parent_id == menuId
      );
    },
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input];
      return paths.some((path) => {
        return this.$route.path.indexOf(path) === 0; // current path starts with this path string
      });
    },
    changeIsOpen() {
      this.isOpen = true;
    },

    setRootId(id) {
      if (id) {
        this.root_id = id;
      }
    },

    isActive(route) {
      return route.url === this.$route.path;
    },
  },
};
</script>

<style>
.active-item {
  height: 2px;
}

.active {
  @apply rounded-md bg-white px-4 py-2 font-medium;
}

.menuCard {
}
</style>
