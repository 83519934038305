<template>
  <div>
    <base-card title="Ganti Email">
      <div class="space-y-4">
        <form
          v-if="verifyEmail && !updating"
          class="space-y-4"
          @submit.prevent="onVerify"
        >
          <alert-message
            type="warning"
            message="Silakan masukkan kode yang dikirimkan ke email baru anda untuk mengaktifkan email baru"
          />
          <base-input disabled inset label="Email Baru" :value="verifyEmail" />
          <base-input
            inset
            label="Konfirmasi Kode"
            type="text"
            placeholder="Masukkan 6 digit kode"
            required
            v-model="verifyToken"
          />
          <div class="flex justify-end gap-2">
            <base-button type="button" color="white" @click="updating = true"
              >Ganti Email</base-button
            >
            <base-button>Konfirmasi Email</base-button>
          </div>
        </form>
        <form v-else @submit.prevent="onSubmit" class="space-y-4">
          <base-input
            inset
            label="Email Baru"
            type="email"
            required
            placeholder="Masukkan Email Baru"
            v-model="email"
          />
          <div class="flex justify-end">
            <base-button>Update</base-button>
          </div>
        </form>
      </div>
    </base-card>

    <loading v-if="loading" />
  </div>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { mapActions } from 'vuex';
import AlertMessage from '@/components/base/AlertMessage.vue';

export default {
  components: { AlertMessage },
  mixins: [requestMixin],
  props: {
    userId: String,
    verifyEmail: String,
  },
  emits: ['created', 'verified'],
  data() {
    return {
      loading: false,
      email: null,
      updating: false,
      verifyToken: null,
    };
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    async onSubmit() {
      this.loading = true;

      const [res, err] = await this.request(
        `/api/v1/users/${this.userId}/-actions/update-email`,
        {
          method: 'patch',
          data: {
            email: this.email,
          },
        }
      );

      if (err) {
        this.createAlert({
          status: 'error',
          data: this.getRequestErrorMessage(err),
        });
      } else {
        this.updating = false;
        this.email = null;

        this.createAlert({
          status: 'success',
          data: 'Email berhasil diubah',
        });

        this.$emit('created', res.email);
      }

      this.loading = false;
    },
    async onVerify() {
      this.loading = true;

      const [, err] = await this.request(
        `/api/v1/users/${this.userId}/-actions/verify-email`,
        {
          method: 'patch',
          data: {
            token: this.verifyToken,
          },
        }
      );

      if (err) {
        this.createAlert({
          status: 'error',
          data: this.getRequestErrorMessage(err),
        });
      } else {
        this.email = null;

        this.createAlert({
          status: 'success',
          data: 'Email berhasil diubah',
        });

        this.$emit('verified');
      }

      this.loading = false;
    },
  },
};
</script>
