<template>
  <base-confirm
    visible
    :with-cancel="false"
    icon-type="badge"
    title="Update Diperlukan"
    message="Silakan refresh halaman dengan menekan Ctrl + Shift + R untuk mendapatkan versi terbaru aplikasi."
    :confirm-text="`Refresh dalam ${refreshTimeout} detik`"
    custom-icon-name="heroicons:arrow-path"
    :closable="false"
    @confirmed="onConfirmed"
  >
    <template #message>
      <p class="text-gray-500">Silakan refresh halaman dengan menekan <span class="font-bold text-gray-900">Ctrl + Shift + R</span> untuk mendapatkan versi terbaru aplikasi.</p>
    </template>
  </base-confirm>
</template>

<script>
import BaseConfirm from './base/BaseConfirm.vue';

export default {
  components: {
    BaseConfirm,
  },
  data() {
    return {
      refreshTimeout: 5,
    };
  },
  methods: {
    onConfirmed() {
      window.location.reload(true);
    },
  },
  mounted() {
    setInterval(() => {
      this.refreshTimeout--;

      if (this.refreshTimeout < 1) {
        window.location.reload(true)
      }
    }, 1000);
  },
};
</script>
